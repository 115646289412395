<template>
    <div>
        <v-card dark class="px-8 py-10" outlined tile v-if="!$isStepReleased(PROJECT_PRODUCTION) && $inGroupArchitects()">
            <h2 class="text-h1">{{ $t('workflow_production.heading') }}</h2>
            <v-card-title class="tiemposText ml-0 pl-0">
                <v-icon x-large color="white" class="mr-5"> mdi-account-clock-outline</v-icon>
                {{ $t('workflow_production.quotation_pending') }}
            </v-card-title>
            <div class="tiemposText mb-2">{{ $t('workflow_production.quotation_pending_explanation') }}</div>
        </v-card>
        <v-card dark class="px-8 py-10" outlined tile v-if="$isStepReleased(PROJECT_PRODUCTION)">
            <h2 class="text-h1">{{ $t('workflow_production.heading') }}</h2>
            <div class="tiemposText mb-2" v-if="!isSigned"> {{ $t('workflow_production.explanation_before_signing') }}</div>
            <div class="tiemposText mb-2" v-else> {{ $t('workflow_production.explanation_when_signed') }}</div>
            <v-divider class="mb-4"></v-divider>
            <v-btn color="primary" @click="$downloadFile(downloadableFile)" class="boxed-icon mr-3 mb-3">
                <v-icon left>{{ $iconByType(downloadableFile) }}</v-icon>
                {{ $t('workflow_production.read_quotation') }}
            </v-btn>
            <upload-dialog v-slot="{ on, attrs }" v-if="!isSigned || $inGroupSuper()" :upload-folder="`projects/${project.id}/quotation`" @afterupload="saveSignedUpload">
                <v-btn v-bind="attrs" v-on="on" :color="isSigned ? '' : 'primary'" outlined :disabled="$isStepApproved(PROJECT_PRODUCTION) > 0" class="boxed-icon mb-3" tile elevation="0">
                    <v-icon left>mdi-file-upload-outline</v-icon>
                    {{ $t('workflow_production.upload_quotation_signed') }}
                </v-btn>
            </upload-dialog>
            <comment-add-dialog v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_PRODUCTION)" v-slot="{ attrs, on }"
                                :context="$t('workflow_production.context_on_quotation')" :project="project" :user="user" :roles="roles">
                <v-btn v-bind="attrs" v-on="on" class="ml-3 mb-3 boxed-icon" color="white" outlined tile elevation="0">
                    {{ $t('project.ui.add_comment') }}
                    <v-icon right>mdi-comment-processing-outline</v-icon>
                </v-btn>
            </comment-add-dialog>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import UploadDialog from '@/components/dialogs/UploadDialog';
import { PROJECT_PRODUCTION } from '@/router/projects';
import { updateFirestoreWorkflowUpload } from '@/firebase/firestore/workflows';
import { PROJECT_UPLOAD_QUOTATION_SIGNED } from '@/utils';
import CommentAddDialog from '@/components/dialogs/CommentAddDialog';

export default {
    name: 'TheProjectQuotation',

    components: {CommentAddDialog, UploadDialog},

    data: () => ({
        agree: Boolean,
        done: Boolean,
    }),

    computed: {
        isSigned () {
            return !!this.workflow.steps[PROJECT_PRODUCTION].signed;
        },
        downloadableFile () {
            return this.workflow.steps[PROJECT_PRODUCTION].signed ? this.workflow.steps[PROJECT_PRODUCTION].signed : this.workflow.steps[PROJECT_PRODUCTION].quotation;
        },

        ...mapGetters('authentication', {
            user: 'getUser',
            roles: 'getRoles',
        }),

        ...mapState('project', {
            project: 'boundInstance',
        }),
        ...mapState('workflow', {
            workflow: 'boundInstance',
        }),
    },

    methods: {
        async saveSignedUpload (uploads) {
            const isApproved = this.$isStepApproved(PROJECT_PRODUCTION);
            await updateFirestoreWorkflowUpload(this.workflow, PROJECT_PRODUCTION, PROJECT_UPLOAD_QUOTATION_SIGNED, uploads[0]);
            !isApproved && await this.$approveStep(PROJECT_PRODUCTION);

            this.$store.dispatch('statusMessage/success', this.$t('Upload successful'));
        },
    }
};
</script>

<style scoped>

</style>
